
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    openStatusText: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const handle = () => {
      emit('handleClick')
    }
    return {
      handle
    }
  }
})
