
import { defineComponent, onMounted, reactive } from 'vue'
import ReasonStatus from './components/ReasonStatus.vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { appCloseWebView, isInAppWebview, setApptitle } from '@/utils/native-app'
import useOpenInfo from '@/hooks/openInfo'

const rejectIcon = require('@/assets/images/status/reject.svg')
const doneIcon = require('@/assets/images/status/done.svg')

export default defineComponent({
  components: {
    ReasonStatus
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const { title } = useOpenInfo()
    const info = reactive({
      icon: '',
      openStatusText: '',
      tips: '',
      btnText: ''
    })
    onMounted(() => {
      const { query } = route
      if (query.status === 'done') {
        info.icon = doneIcon
        info.openStatusText = '开户已完成'
        info.tips = '多谢阁下提供资料，阁下已经完成整个开户程序待完成审批后，阁下将会收到电邮通知'
        info.btnText = '完成'
      } else {
        info.icon = rejectIcon
        info.openStatusText = '开户未完成'
        info.tips = '请及时上传转账证明，以完成整个开户程序'
        info.btnText = '完成'
      }
    })

    const handleClick = async () => {
      if (isInAppWebview()) {
        await appCloseWebView()
      } else {
        router.push('/')
      }
    }

    onMounted(() => {
      setApptitle(title)
    })

    onBeforeRouteLeave(() => {
      handleClick()
    })

    return {
      rejectIcon,
      info,
      handleClick
    }
  }
})
