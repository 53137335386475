import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2bc7e978")
const _hoisted_1 = { class: "status" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reason_status = _resolveComponent("reason-status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_reason_status, {
      imgUrl: _ctx.info.icon,
      openStatusText: _ctx.info.openStatusText,
      tips: _ctx.info.tips,
      btnText: _ctx.info.btnText,
      onHandleClick: _ctx.handleClick
    }, null, 8, ["imgUrl", "openStatusText", "tips", "btnText", "onHandleClick"])
  ]))
}